import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import BaseButton from './BaseButton';
import skinsStyle from './styles/SolidColorMenuButtonNSkin.scss';

const SolidColorMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  return (
    <BaseButton
      {...props}
      skinsStyle={skinsStyle}
      skin={'SolidColorMenuButtonNSkin'}
    />
  );
};

export default SolidColorMenuButtonNSkin;
